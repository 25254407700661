@layer base {
  html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 100%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif;
  }

  .gtamerica {
    font-family:
      var(--font-GtAmerica),
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Helvetica,
      Arial,
      sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-family: inherit;
  }

  body {
    font-weight: normal;
    font-family: inherit;
    margin: 0;
    background-color: #ffffff;
    padding: 0;
  }

  button {
    margin: 0;
    color: inherit;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .logo__depop-icon {
    width: 90px;
    height: 23px;
    display: block;
  }

  .container__global-error {
    display: flex;
    height: 100vh;
    padding: 16px;
    flex-direction: column;

    @media (min-width: 576px) {
      padding: 16px 32px;
    }
  }

  .content__global-error {
    display: flex;
    flex: 1;
    align-items: center;
    font-size: 1.5rem;
  }
}
